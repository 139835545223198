import React, {useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import {getCouponPdf} from '../../lib/emmiApi'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import {Navigate} from "react-router-dom"
import './StartBasic.scss'
import Text from "../i18n/Text"
import LOG from "wipi-common/src/lib/logging/logger"

export default function DownloadCouponButton({caseId, hasIdent, couponData, setCouponData, backToBranchUrl, search}) {
    const [isDownloading, setDownloading] = useState(false);
    const [redirectTo, setRedirectTo] = useState(false);

    const downloadCoupon = useCallback(() => {
        setDownloading(true);
        LOG.logInfo(LOG_EVENTS.BA_COUPON_DOWNLOAD);
        Promise.resolve(couponData || downloadPdf(caseId, setCouponData))
            .then(offerAsFile)
            .catch(() => {
                setRedirectTo(false);
            })
            .then(() => {
                setDownloading(false);
                setRedirectTo(true);
            })
    }, [caseId, couponData, setCouponData]);


    if (backToBranchUrl && redirectTo) {
        return <Navigate to={{pathname: "/basic/after-coupon-download", search}}/>
    }
    return <button className={"pi-btn pi-btn--primary btn-block"+ (!hasIdent || isDownloading ? " pi-btn--disabled" : "")} onClick={downloadCoupon} data-test-id="190523bc-40ef"
                   disabled={!hasIdent || isDownloading}>
        <Text k="startBasic.dlCouponBtn"/>
    </button>
}

DownloadCouponButton.propTypes = {
    caseId: PropTypes.string.isRequired,
    couponData: PropTypes.object,
    hasIdent: PropTypes.bool,
    setCouponData: PropTypes.func.isRequired
}

function downloadPdf(caseId, setPdfData) {
    return getCouponPdf(caseId)
        .then(data => {
            setPdfData(data)
            return data
        })
}

function offerAsFile({documentData, mimeType, fileName}) {
    if (window.navigator.msSaveBlob) { // Internet Explorer
        const blob = b64toBlob(documentData, mimeType)
        window.navigator.msSaveBlob(blob, fileName)
    } else {
        const link = document.createElement('a')
        link.href = `data:application/octet-stream;base64,${documentData}`
        link.setAttribute('download', fileName)
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
    }
}

function b64toBlob(b64Data, contentType, sliceSize = 512) {
    const byteCharacters = atob(b64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, {type: contentType})
}
