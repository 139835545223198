import React, {Component} from 'react'
import {Header} from '../header/Header'
import Footer from '../footer/Footer'
import I18nContext from '../i18n/I18nContext'
import {doEasyConnect, getCaseInfo, postUserStart, putContactData} from '../../lib/emmiApi'
import Spinner from '../misc/Spinner'
import UnexpectedError from '../misc/UnexpectedError'
import {
    addCaseIdParam,
    isPathInUrl,
    parseCaseParams,
    removeEasyConnectParams,
    updateQueryParameter
} from 'wipi-common/src/lib/util'
import DataCompletion from '../data-completion/DataCompletion'
import {LOG_EVENTS} from 'wipi-common/src/lib/logging/events'
import CaseGoneError from '../../lib/CaseGoneError'
import CaseGoneInfo from '../misc/CaseGoneInfo'
import CaseInfo from '../case-info/CaseInfo'
import MainContainer from '../misc/MainContainer'
import {
    determineDefaultLanguage,
    loadAppConfig,
    DEFAULT_LANGUAGE,
    getLanguageByCode
} from "wipi-common/src/lib/i18n";
import RateLimitExceededError from '../../lib/RateLimitExceededError'
import RateLimitExceeded from '../misc/RateLimitExceeded'
import WhitelabelStyle from './WhitelabelStyle'
import ErrorBoundary from "../misc/ErrorBoundary";
import LOG from "wipi-common/src/lib/logging/logger";
import CaseConflictInfo from "../misc/CaseConflictInfo";
import CaseConflictError from "../../lib/CaseConflictError";
import CaseCancelledInfo from "../misc/CaseCancelledInfo"
import CaseCancelledError from "../../lib/CaseCancelledError";

class App extends Component {

    state = {
        selectedLanguage: DEFAULT_LANGUAGE,
        caseInfo: null,
        caseInfoError: false,
        queryParams: window.location.search,
        hasResourceData: false
    }

    componentDidMount() {
        localStorage.removeItem("signingWorkflow")
        determineDefaultLanguage()
            .then(result => {
                LOG.logInfo(LOG_EVENTS.CE_LANGUAGE_SELECTION, undefined, undefined, result)
                let selectedLanguage = getLanguageByCode(result.selectedLanguage)
                this.setState(() => ({selectedLanguage: selectedLanguage}))
                this.init(selectedLanguage)
            })
    }

    init = language => {
        const code = language.value
        localStorage.setItem("language", code)
        loadAppConfig(code)
            .then(() => {
                updateQueryParameter("lang", language.value)
                this.setState(() => ({
                    selectedLanguage: language,
                    hasResourceData: true
                }))
                this.updateQueryParam()
            })
            .catch(error => {
                LOG.logError(LOG_EVENTS.REQUEST_ERROR, 'Unable to load app config')
            })
        this.loadCaseInfo()
    }

    updateQueryParam = () => {
        this.setState(() => ({
            queryParams: window.location.search,
        }))
    }

    changeLanguage = language => {
        this.init(language)
    }

    loadCaseInfo = () => {
        try {
            const caseParams = parseCaseParams()
            if (!!caseParams.caseId) {
                this.processCaseInfoRequest(getCaseInfo(caseParams.caseId))
            } else {
                this.processCaseInfoRequest(doEasyConnect(caseParams.accountingNumber, caseParams.referenceId))
                    .then(this.updateUrl)
            }
        } catch (error) {
            LOG.logError(LOG_EVENTS.MR_CASE_CHECK_RESULT, 'Could not extract case params')
            this.setState(() => ({caseInfoError: true}))
        }
    }

    getDisplayContent = () => {
        const {caseInfo, caseInfoError, isCaseCancelled, isCaseGone, isConflict, isRateLimitExceed, hasResourceData, caseCancelledMessage} = this.state
        if (hasResourceData) {
            if (caseInfoError) {
                LOG.logWarn(LOG_EVENTS.UNEXPECTED_ERROR)
                return <UnexpectedError/>
            } else if(isCaseCancelled){
                return <CaseCancelledInfo message={caseCancelledMessage}/>
            }else if (isCaseGone) {
                return <CaseGoneInfo/>
            } else if (isConflict) {
                return <CaseConflictInfo/>
            } else if (isRateLimitExceed) {
                return <RateLimitExceeded/>
            } else if (caseInfo && caseInfo.contactData) {
                LOG.logInfo(LOG_EVENTS.DC_CONTACT_DATA)
                return <DataCompletion caseInfo={caseInfo} submitData={this.submitContactData}
                                       serverValidationErrors={this.state.serverValidationErrors}/>
            } else if (caseInfo && caseInfo.modules) {
                return <CaseInfo caseInfo={caseInfo} queryParams={this.state.queryParams}/>
            } else {
                return <Spinner circleStroke="#ccc"/>
            }
        } else {
            return <Spinner circleStroke="#ccc"/>
        }
    }

    submitContactData = data => {
        LOG.logInfo(LOG_EVENTS.DC_SEND_CONTACT_DATA)
        return putContactData(this.state.caseInfo.caseId, data)
            .then(caseInfo => this.setState(() => ({caseInfo})))
            .catch(data => {
                const stateUpdate = (data instanceof Error) ? {caseInfoError: true} : {serverValidationErrors: data}
                this.setState(() => stateUpdate)
            })
    }

    processCaseInfoRequest = request => {
        return request
            .then(caseInfo => {
                if (caseInfo.signingWorkFlow && !localStorage.getItem("signingWorkflow")) {
                    localStorage.setItem("signingWorkflow", "true")
                    //Force to change language to default, because the user has no possibility to change the language anymore, tbd!
                    this.changeLanguage(DEFAULT_LANGUAGE)
                } else if (!caseInfo.signingWorkFlow) {
                    localStorage.removeItem("signingWorkflow")
                }
                this.setState(() => ({caseInfo}))
                //skip callback url to call userStart again
                if (!isPathInUrl(['/eIdError', '/eIdSuccess']))
                    postUserStart(caseInfo.caseId)
                return caseInfo
            })
            .catch(error => {
                let stateUpdate = {}
                if (error instanceof CaseGoneError) {
                    stateUpdate = {isCaseGone: true}
                }else if (error instanceof CaseCancelledError){
                    stateUpdate = {isCaseCancelled : true, caseCancelledMessage: error.message}
                }else if (error instanceof CaseConflictError) {
                    stateUpdate = {isConflict: true}
                } else if (error instanceof RateLimitExceededError) {
                    stateUpdate = {isRateLimitExceed: true}
                } else {
                    stateUpdate = {caseInfoError: true}
                }
                this.setState(() => stateUpdate)
            })
    }

    updateUrl = (caseInfo) => {
        if (caseInfo) {
            let search = removeEasyConnectParams(window.location.search)
            search = addCaseIdParam(search, caseInfo.caseId)
            window.history.replaceState({}, window.document.title, window.location.pathname + search)
            this.updateQueryParam()
        }
    }

    render() {
        const {caseInfo, selectedLanguage, hasResourceData} = this.state
        return <I18nContext.Provider value={{"lang": selectedLanguage.value, "ready": hasResourceData}}>
            {caseInfo && caseInfo.whitelabel && <WhitelabelStyle {...this.state.caseInfo.whitelabel} />}

            <Header changeLanguage={this.changeLanguage}
                    signingWorkflow={caseInfo && caseInfo.signingWorkFlow}
                    selectedLanguage={selectedLanguage}
                    whitelabel={!isCaseLoadedNoWhitelabel(this.state)}
                    principalDisplayName={caseInfo && caseInfo.principalDisplayName}
                    principalDisplayLogo={caseInfo && caseInfo.principalDisplayLogo}/>
            <MainContainer><ErrorBoundary>{this.getDisplayContent()}</ErrorBoundary></MainContainer>

            <Footer legalInfo={caseInfo && caseInfo.legalInfo}/>
        </I18nContext.Provider>
    }
}

function isCaseLoadedNoWhitelabel({caseInfo, caseInfoError, isCaseCancelled, isCaseGone, isConflict, isRateLimitExceed}) {
    return isConflict || isRateLimitExceed || caseInfoError || isCaseCancelled || isCaseGone || (caseInfo && !caseInfo.whitelabel)
}

export default App