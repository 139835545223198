import React, {Component} from 'react'

import './IdentSuccess.scss'
import {getEidIdent} from '../../../lib/emmiApi'
import Spinner from './Spinner'
import PropTypes from 'prop-types'
import verifiedIcon from '../../../assets/icons/verified.svg'
import {getI18nTextWithContext} from "wipi-common/src/lib/i18n"
import Text from "../../i18n/Text"
import I18nContext from "../../i18n/I18nContext";

const REDIRECT_TIMEOUT_SECONDS = 5

export default class IdentSuccess extends Component {
    static contextType = I18nContext
    eidIdent

    constructor(props) {
        super(props);
        getEidIdent(props.caseId).then(ident => {
            this.eidIdent = ident;
            this.forceUpdate()
        })
    }

    redirect = () => {
        const termination = this.eidIdent.modules.identStatus
        window.location.assign(termination[termination.continueButton.target].webUrl)
    }

    render() {
        if (!this.eidIdent)
            return <Spinner/>

        const identCallTermination = this.eidIdent.modules.identStatus
        const principalDisplayName = this.eidIdent.principalDisplayName
        const text = identCallTermination.subtitle

        if (hasCallbackUrl(identCallTermination)) {
            setTimeout(this.redirect, REDIRECT_TIMEOUT_SECONDS * 1000)
        }

        return <div>
            <h3 className="text-center">{text}</h3>
            <div className="text-center">
                <img src={verifiedIcon} alt="" className="img-fluid m-4 mw-100" style={{width: '72px'}}
                     data-test-id="0df48425-8be9"/>
            </div>

            <div className="container">
                <div className="pi-grid">
                    <div className="pi-ident-success-wrapper">
                        <div className="pi-section pi-section--center">
                            <div className="pi-section__content" data-test-id="fb7d4972-d08c">
                                <span className="pi-illustration pi-illustration--sm pi-illustration--success"/>
                                {hasCallbackUrl(identCallTermination)
                                    ? <Redirect redirectFn={this.redirect}
                                                buttonText={identCallTermination.continueButton.text}/>
                                    : getI18nTextWithContext(this.context, 'common.identPre') + ` ${principalDisplayName} `
                                        + getI18nTextWithContext(this.context, 'common.identPost')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

function Redirect({redirectFn, buttonText}) {
    return <React.Fragment>
        <p data-test-id="d40cff5c-faf5">
            <Text k='common.redirectPre'/> <strong>{REDIRECT_TIMEOUT_SECONDS} <Text k='common.seconds'/></strong> <Text
            k='common.redirectPost'/>
        </p>
        <p data-test-id="0c309c3f-cf46"><Text k='startEId.error.redirect'/></p>
        <button data-test-id="a1187592-61a7" onClick={redirectFn} className="pi-btn pi-btn--primary">
            {buttonText}
        </button>
    </React.Fragment>
}

function hasCallbackUrl(termination) {
    return termination
        && termination.continueButton
        && termination.continueButton.target
        && termination[termination.continueButton.target]
        && termination[termination.continueButton.target].webUrl
}

IdentSuccess.propTypes = {
    caseId: PropTypes.string.isRequired
}