import React from 'react'
import PropTypes from 'prop-types'
import {Link, useLocation} from "react-router-dom"
import Text from "../i18n/Text";

export function BackButton({to, isMobileDevice}) {
    const location = useLocation()

    return <p>
        <Link to={{pathname: to, search: location.search}} className={'btn btn-secondary' + (isMobileDevice ? ' btn-block' : '')} data-test-id="80100345-ad6c">
            <Text k="common.back" />
        </Link>
    </p>
}

BackButton.propTypes = {
    to: PropTypes.string.isRequired,
    queryParam:PropTypes.object
}

export default BackButton
