import React from 'react'
import FAQCard from './FAQCard'
import './FAQ.scss'
import Text from "../i18n/Text";

export default function FAQ({faq}) {
    if (!faq || faq.length === 0) {
        return null
    }

    let questions = sortList(faq.filter(item => item.endsWith(".q")))
    let answers = sortList(faq.filter(item => item.endsWith(".a")))

    return <section className="pi-faq accordion">
        <h2 className="h4 my-5 text-center headless" data-test-id="fe9d3993-6c63"><Text k='common.faq'/></h2>
        <span id="faq" data-test-id="ab7d3921-2263"/>
        {
            questions.map((f, idx) => <FAQCard header={f} body={answers[idx]} cardId={idx} key={idx}/>)
        }
    </section>
}

function sortList(list) {
    return list.sort((a, b) => {
        let aIdx=fetchIndex(a)
        let bInx=fetchIndex(b)
        if (aIdx > bInx)
            return 1
        if (aIdx < bInx)
            return -1
        return 0
    })
}

function fetchIndex(text){
    let idx=text.match(/faq\.(\d+)\.[a,q]$/)
    if(idx){
        return parseInt(idx[1])
    }
    return -1
}